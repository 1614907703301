// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Jua";
  src: url("https://fonts.gstatic.com/s/jua/v11/co3KmW9ljjY2w1U.woff2");
  font-weight: 400;
}
`, "",{"version":3,"sources":["webpack://./src/font.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,qEAAqE;EACrE,gBAAgB;AAClB","sourcesContent":["@font-face {\n  font-family: \"Jua\";\n  src: url(\"https://fonts.gstatic.com/s/jua/v11/co3KmW9ljjY2w1U.woff2\");\n  font-weight: 400;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
